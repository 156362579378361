
/* nav-pills */
#left-tabs-example-tab-ccumulator {
    padding: "0.5rem 0.5rem";
    color:#4b7bec;
}
#left-tabs-example-tab-accumulator.active {
    color: #fff;
    background-color: #4b7bec;
}

#left-tabs-example-tab-solar {
    padding: "0.5rem 0.5rem";
    color:#f7b731;
}
#left-tabs-example-tab-solar.active {
    color: #fff;
    background-color: #f7b731;
}

#left-tabs-example-tab-hotWater {
    padding: "0.5rem 0.5rem";
    color:#20bf6b;
}
#left-tabs-example-tab-hotWater.active {
    color: #fff;
    background-color: #20bf6b;
}

#left-tabs-example-tab-heatPump {
    padding: "0.5rem 0.5rem";
    color:#fc5c65;
}
#left-tabs-example-tab-heatPump.active {
    color: #fff;
    background-color: #fc5c65;
}

#left-tabs-example-tab-heatingBoiler {
    padding: "0.5rem 0.5rem";
    color:#a561e5;
}
#left-tabs-example-tab-heatingBoiler.active {
    color: #fff;
    background-color: #a561e5;
}

/* Navbar logo position */
.navbar-brand {
    margin-left: 1rem;
}

/* Range slider tooltip */
.range-slider__wrap .range-slider__tooltip {
    z-index: 1010 !important;
}

/* CRUD modal */
#view-modal-header {
    background-color: #007bff;
    color: #ffffff;
}

#add-modal-header {
    background-color: #28a745;
    color: #ffffff;
}

#edit-modal-header {
    background-color: #ffc107;
    color: #212529;
}

#remove-modal-header {
    background-color: #dc3545;
    color: #ffffff;
}

.modal-body .card {
    border-radius: 0;
}

.modal-body .card .list-group {
    border-radius: 0;
}

/* Profile modal */
#profile-modal-header {
    background-color: #ffc107;
    color: #212529;
}

/* Table 2 styles */
.table2-header {
    background-color: #161719;
    color: #6c757d;
    border-top: 0 !important;
    width: 1rem;
}

.search-label {
    width: 100%;
}

.react-bootstrap-table table {
    table-layout: auto;
}


.row .react-bootstrap-table-pagination {
    display: block;
}

/* Led green || off, optional blink */
.led-green {
    margin: 0 auto;
    width: 32px;
    height: 32px;
    background-color: #ABFF00;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px;
    -webkit-animation: blinkGreen 0.5s infinite;
    -moz-animation: blinkGreen 0.5s infinite;
    -ms-animation: blinkGreen 0.5s infinite;
    -o-animation: blinkGreen 0.5s infinite;
    animation: blinkGreen 0.5s infinite;
}
.led-green.off {
    background-color: #888888;
    box-shadow: none;
}
/* @-webkit-keyframes blinkGreen {
    from { background-color: #ABFF00; }
    50% { background-color: #304701; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #304701 0 2px 0; }
    to { background-color: #ABFF00; }
}
@-moz-keyframes blinkGreen {
    from { background-color: #ABFF00; }
    50% { background-color: #304701; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #304701 0 2px 0; }
    to { background-color: #ABFF00; }
}
@-ms-keyframes blinkGreen {
    from { background-color: #ABFF00; }
    50% { background-color: #304701; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #304701 0 2px 0; }
    to { background-color: #ABFF00; }
}
@-o-keyframes blinkGreen {
    from { background-color: #ABFF00; }
    50% { background-color: #304701; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #304701 0 2px 0; }
    to { background-color: #ABFF00; }
}
@keyframes blinkYellow {
    from { background-color: #ABFF00; }
    50% { background-color: #304701; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #304701 0 2px 0; }
    to { background-color: #ABFF00; }
} */